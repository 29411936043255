import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {Modal} from '@/components/common-components/components';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faListTree, faArrowUpRightFromSquare, faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {Checkbox, Dropdown, Menu, Radio, Spin, Tooltip} from 'antd';
import {notification} from '@/utils/notifications';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {openUrl} from '@/utils/router';
import {faArrowUpFromLine, faChevronDown, faChevronUp, faCircleCheck, faFileLines, faSortDown, faWandMagicSparkles} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {PAGES_API} from '@/api/content-optimizer';
import {notification as notificationV2} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import {PRO_PLAN_ID} from '@/constants';
import {OneClickPublisher} from '../../../../landing-page-optimizer/page-editor/LeftSide/TopSection/title-editor/OneClickPublisher/OneClickPublisher';
import {convertToTransparent} from '@/utils/colors';
import {topicalMapStepsTitles} from '../../../Constants';
import {BackButton, DeleteButton, GenerateArticleMenu, ModalWrapper, PluginButton, TopicalMapStepsWrapper, Wrapper} from '../../../style';
import {StyledSpin} from '../../../Utils/styledSpin';
import {GenerateButton} from '..';
import FreezeWrapper from '../../freezTooltip';
import {observer} from 'mobx-react-lite';
import {useRouter} from 'next/router';

interface TopicalMapModalTableContentProps {
  keysWithChildren: any;
  topicMapDataList: any;
  generatingBulkArticles: any;
  setGeneratingBulkArticles: any;
  selectedArticlesToGenerate: any;
  setSelectedArticlesToGenerate: any;
  setTopicalMapArticle: any;
  topicalMapArticle: any;
  setDataLoader: any;
  setClickedValue: any;
  tableData: any;
  setTableData: any;
  tableDataMapping: any;
  openCategoryDropdown: any;
  topicalMap: any;
  ExportButton: any;
  onChangeCategoryVisibility: any;
  searchIntentDropdown: any;
  categoryMenu: any;
  openTopicalMapModal?: boolean;
  setOpenTopicalMapModal?: (value: boolean) => void;
  componentIssueType: string;
  setActiveCategories: Dispatch<SetStateAction<any[]>>;
  isInContentTopicMap?: boolean;
  searchInput: any;
}

const filterRadioButtons = [
  {
    label: 'All Ideas',
    value: 'all-ideas',
  },
  {
    label: 'Topic map',
    value: 'topic-map',
  },
  {
    label: 'Created',
    value: 'created',
  },
];
const defaultCounty = {
  countryCode: 'US',
  location: 'United States',
  locationId: 2840,
};

export const TopicalMapModalTableContent: React.FC<TopicalMapModalTableContentProps> = observer((
  {
    keysWithChildren,
    topicMapDataList,
    generatingBulkArticles,
    setGeneratingBulkArticles,
    selectedArticlesToGenerate,
    setSelectedArticlesToGenerate,
    setTopicalMapArticle,
    topicalMapArticle,
    setDataLoader,
    setClickedValue,
    tableData,
    setTableData,
    tableDataMapping,
    openCategoryDropdown,
    topicalMap,
    ExportButton,
    onChangeCategoryVisibility,
    searchIntentDropdown,
    categoryMenu,
    componentIssueType,
    setActiveCategories,
    isInContentTopicMap,
    searchInput,
  }) => {
  const {ottoV2Store: {loadIssueTableData, ottoUrls, deletingTopicalMap, topicalMapUpdateArticle, createTopicalMapBulkArticles, getOttoV2Project},
    settings: {customer: {profile: {address, plan}, getCustomerQuota}},
    contentOptimizer: {
      pages: {incrementInTotalArticle},
      aiOutline: {setTopUpBulkVisibility},
      wpPublish: {wpArray, setShowInstructionsModal},
      contentIdeas: {getTopicalMapData, loadSingleTopicMapData},
    },
  } = useStore('');
  const router = useRouter();

  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>('');
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(defaultCounty);
  const [errorId, setErrorId] = useState([]);
  const wpContainerRef = useRef(null);

  const uuid = getSingleUrlParam('uuid');
  const generateColor = si => {
    if (si === 'informational') {
      return '#34AEF3';
    } else if (si === 'commercial') {
      return '#F1AA3E';
    } else if (si === 'transactional') {
      return '#1F9068';
    } else if (si === 'navigational') {
      return '#B98CE4';
    } else {
      return '#ffffff';
    }
  };

  useEffect(() => {
    if (address?.defaultLocation) {
      setSelectedCountry({
        countryCode: address?.defaultLocation,
        location: address?.countryName,
        locationId: address?.locationId,
      });
    }
  }, [address?.defaultLocation]);

  const updateTopicalMapArticle = async articleData => {
    const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
    const payload = {
      id: articleData?.id,
      titleUuid: articleData?.titleUuid,
      pageUuid: articleData?.pageUuid,
    };
    await topicalMapUpdateArticle(payload);
    const params = {
      uuid,
      issue_type: componentIssueType,
      page_size: filter?.length ? filter[0].pageSize : 10,
      page: filter?.length ? filter[0].page : 1,
      is_loading: false,
    };
    if (isInContentTopicMap) {
      await loadSingleTopicMapData(Number(router.query['topicId']), true);
    } else {
      await loadIssueTableData(params, false, true);
    }
  };

  const groupById = data => {
    const result = {};
    data.forEach(item => {
      if (!result[item.id]) {
        result[item.id] = [];
      }
      result[item.id].push(item.titleUuid);
    });
    return result;
  };

  const deleteArticle = async (contentTopic, topicalMapArticleDataParam) => {
    setOpenModal(false);
    try {
      const selectedArticle = topicalMapArticleDataParam?.find(i => i?.title === contentTopic);
      setDeleteId(selectedArticle?.titleUuid);
      const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
      const payload = {
        id: selectedArticle?.id,
        titleUuid: selectedArticle?.titleUuid,
        shouldDelete: true,
        publicShareHash: getOttoV2Project?.publicShareHash,
      };
      await topicalMapUpdateArticle(payload);
      setDataLoader(true);
      const params = {
        uuid,
        issue_type: componentIssueType,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        is_loading: false,
      };
      if (isInContentTopicMap) {
        await getTopicalMapData();
      } else {
        await loadIssueTableData(params, false, true);
      }
      notificationV2.success('', 'Article deleted successfully', 'Close');
      setDeleteId('');
      setOpenModal(false);
      setDeleteModalData(null);
    } catch (e) {
      setDeleteId('');
      setOpenModal(false);
      setDeleteModalData(null);
      const errorMessage = apiError(e);
      notification.error('', errorMessage);
    }
  };

  const onGenerateArticle = async (articles: any[], singleArticle?: boolean) => {
    setGeneratingBulkArticles(true);
    try {
      const articleGroup = groupById(articles);
      const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
      for (let index = 0; index < Object.keys(articleGroup).length; index++) {
        const key = Object.keys(articleGroup)[index];
        const uuids = articleGroup[key];
        const payload = {
          'uuids': uuids,
        };
        await createTopicalMapBulkArticles(key, payload, singleArticle);
      }
      setDataLoader(true);
      const params = {
        uuid,
        issue_type: componentIssueType,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        is_loading: false,
      };
      setDeleteModalData(null);
      if (isInContentTopicMap) {
        await loadSingleTopicMapData(Number(router.query['topicId']), true);
      } else {
        await loadIssueTableData(params, false, true);
      }
      await getCustomerQuota();
      incrementInTotalArticle();
      notificationV2.success('Success', `Article${singleArticle ? '' : 's'} created successfully.`);
    } catch (e) {
      setGeneratingBulkArticles(false);
      if (e?.response?.status && e?.response?.status === 429) {
        plan === PRO_PLAN_ID && setTopUpBulkVisibility(true);
      } else if (e?.response?.status && e?.response?.status === 406) {
        notificationV2.error(`Could not generate ${singleArticle ? 'article' : 'bulk articles'}`, e?.response?.data?.keywordsList, false);
      } else {
        const errorMessage = apiError(e);
        notification.error('', errorMessage);
      }
    }
  };

  const onOutline = async (child: any) => {
    const alreadyExist = topicalMapArticle.some(i => i?.title === child?.name);
    if (!alreadyExist) {
      const newArticle = {
        id: child?.id,
        titleUuid: child?.titleUuid,
        title: child?.name,
        isLoading: true,
        pageUuid: '',
      };
      setTopicalMapArticle(prev => [...prev, newArticle]);
    } else {
      setTopicalMapArticle(prev => prev.map(i => {
        if (i?.title === child?.name) {
          return {...i, isLoading: true};
        }
        return i;
      }));
    }
    const payload = {
      ...{
        'should_generate_outline': true,
        'keywords_list': [child?.name],
        'location': 'United States',
        'location_id': 2840,
      },
      ...(selectedCountry?.location && {location: selectedCountry?.location}),
      ...(selectedCountry?.locationId && {location_id: selectedCountry?.locationId}),
    };

    const topicalMapArticleDataParam = [...topicalMapArticle, {
      id: child?.id,
      titleUuid: child?.titleUuid,
      title: child?.name,
      isLoading: true,
      pageUuid: '',
    }];
    try {
      const res = await PAGES_API.generateOutlineWithoutContent(payload);
      setTimeout( async ()=> {
        if (res?.uuid) {
          getCustomerQuota();
          incrementInTotalArticle();
          const selectedArticle = topicalMapArticleDataParam?.find(i => i?.title === child?.name);
          const articleData = {...selectedArticle, pageUuid: res?.uuid};
          if (articleData?.pageUuid) {
            await updateTopicalMapArticle(articleData);
            setTopicalMapArticle(prev => {
              const updatedData = prev.map(i => {
                if (i?.title === child?.name) {
                  return {...i, pageUuid: res?.uuid, isLoading: res?.aiInProgress};
                }
                return i;
              });
              return updatedData;
            });
            setDataLoader(true);
            notificationV2.success('Success', 'Outline generated successfully.');
          }
        }
      }, 1000);
    } catch (e) {
      setTopicalMapArticle(prev => {
        const updatedData = prev.map(i => {
          if (i?.title === child?.name) {
            return {...i, isLoading: false};
          }
          return i;
        });
        return updatedData;
      });
      if (e?.response?.status && e?.response?.status === 429) {
        plan === PRO_PLAN_ID && setTopUpBulkVisibility(true);
      } else if (e?.response?.status && e?.response?.status === 406) {
        notificationV2.error('Could not generate Outline', e?.response?.data?.keywordsList, false);
      } else {
        const errorMessage = apiError(e);
        notification.error('', errorMessage);
      }
    }
  };

  const onChangeActive = (checked: boolean, value: string) => {
    setActiveCategories(prev =>checked ? [...prev, value] : prev?.filter(item => item !== value));
  };

  const IdeaRow = ({child}) => {
    const hasLength = !!topicalMapArticle?.filter(i => i?.title === child?.name)?.length;
    const isLoading = topicalMapArticle?.find(i => i?.title === child?.name)?.isLoading;
    const isGenerating = (hasLength && isLoading) || (!child?.pageUuid && child?.aiGenerationStatus === 'PENDING') || (selectedArticlesToGenerate?.filter(article => article?.titleUuid === child?.titleUuid)?.length && generatingBulkArticles);
    return (
      <div key={child?.name || Math.random()} style={{width: '100%', display: 'flex', boxShadow: '0px 1px 0px 0px #E7E7E7', alignItems: 'flex-start', padding: '12px 12px'}}>
        <CheckboxWrapper style={{flexBasis: '6%'}}>
          <TableCheckbox defaultChecked={child?.isSelected} onClick={() => setClickedValue(child?.value)} />
        </CheckboxWrapper>
        <div style={{flexBasis: '6%', display: 'flex', alignItems: 'center', gap: '8px'}}>
          {child?.searchIntent?.length ?
            child?.searchIntent?.map((si, index) => (
              <StyledTag key={index} style={{color: generateColor(si?.toLowerCase()), backgroundColor: convertToTransparent(generateColor(si?.toLowerCase()), '10%')}}>
                {si === 'informational' ? 'I' : si === 'commercial' ? 'C' : si === 'transactional' ? 'T' : si === 'navigational' ? 'N' : ''}
              </StyledTag>
            )):
            null}
        </div>
        <Keyword style={{flexBasis: '20%'}}>{child?.name}</Keyword>
        <Title style={{flexBasis: '30%', fontWeight: 400}}>{child?.titleName}</Title>
        <ActionsWrapper style={{display: 'flex', alignItems: 'center', gap: '14px', flexBasis: '38%'}}>
          <TopicalMapStepsWrapper style={{marginTop: '0px'}}>
            <div className='steps'>
              {topicalMapStepsTitles.map((title, index) => (
                <div
                  key={index}
                  className={`section ${
                    (index === 0) ? 'active-section ' :
                      (index === 1 && !isLoading && child?.pageUuid) ? 'active-section ' :
                        (index === 2 && !isLoading && child?.wpPublishedAtUrl) ? 'active-section ' : ''
                  } ${(index !== 0) ? '' : ' restricted'} ${!topicalMap?.project && 'disabled-btn'}`}
                  onClick={() => {}}
                >
                  <div className={`step ${(index === 0 && !isLoading && child?.pageUuid) ? 'active' : ''}`}>
                    {index === 0 ?
                      (hasLength && isLoading) || (!child?.pageUuid && child?.aiGenerationStatus === 'PENDING') || (selectedArticlesToGenerate?.filter(article => article?.titleUuid === child?.titleUuid)?.length && generatingBulkArticles) ?
                        <FreezeWrapper>
                          <ViewButton style={{color: '#7F4EAD', backgroundColor: 'rgba(127, 78, 173, 0.15)', width: '162px', padding: '6px 12px'}}>
                            <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#7F4EAD'}} spin />} />Generating...
                          </ViewButton>
                        </FreezeWrapper> :
                        child?.pageUuid ? <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                          <FontAwesomeIcon icon={faCircleCheck} color='#7F4EAD' fontSize={14} />
                          <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                            {child?.searchIntent?.length && child?.searchIntent?.map(si => (
                              <StyledTag key={si} style={{color: generateColor(si.toLowerCase()), backgroundColor: convertToTransparent(generateColor(si.toLowerCase()), '10%')}}>
                                {si === 'informational' ? 'I' : si === 'commercial' ? 'C' : si === 'transactional' ? 'T' : si === 'navigational' ? 'N' : ''}
                              </StyledTag>
                            ))}
                          </div>
                          <GenerateButton style={{color: '#4E5156', width: '84px'}} onClick={() => openUrl(`/content/seo-content-assistant/${child?.pageUuid}`, '_blank')}>View content</GenerateButton>
                        </div> : <FreezeWrapper><Dropdown trigger={['click']}
                          disabled={!topicalMap?.project}
                          overlayStyle={{top: '974px', left: '972px'}}
                          overlay={<GenerateArticleMenu>
                            <Menu.Item className='menu-item'
                              onClick={() => {
                                setSelectedArticlesToGenerate([{id: child?.id, titleUuid: child?.titleUuid}]);
                                onGenerateArticle([{id: child?.id, titleUuid: child?.titleUuid}], true);
                              }}>
                              <FontAwesomeIcon icon={faFileLines} color='#121212' fontSize={14}/>Full Article with AI
                            </Menu.Item>
                            <Menu.Item className='menu-item'
                              onClick={() => {
                                setSelectedArticlesToGenerate([{id: child?.id, titleUuid: child?.titleUuid}]);
                                onOutline(child);
                              }}><FontAwesomeIcon icon={faListTree} color='#121212' fontSize={14}/>Headings Outline with AI</Menu.Item>
                          </GenerateArticleMenu>}>
                          <Tooltip title={!topicalMap?.project && 'No project folder attached to this topical map'} overlayInnerStyle={{borderRadius: '10px'}}>
                            <ViewButton
                              style={{
                                cursor: !topicalMap?.project ? 'not-allowed' : 'pointer',
                                color: !topicalMap?.project ? '#666666' : '#7F4EAD',
                                backgroundColor: !topicalMap?.project ? '#cccccc' : 'rgba(127, 78, 173, 0.15)', width: '162px',
                              }}>
                              <FontAwesomeIcon icon={faWandMagicSparkles} color={!topicalMap?.project ? '#666666' : '#7F4EAD'} fontSize={14} />{title}
                            </ViewButton>
                          </Tooltip>
                        </Dropdown> </FreezeWrapper>: index === 1 ? (<FreezeWrapper><ViewButton
                        style={{color: (!isLoading && child?.pageUuid && child?.wpPublishedAtUrl) ? '#4E5156' :
                          (!isLoading && child?.pageUuid) ? '#7F4EAD' : '#A3A4A4',
                        backgroundColor: (!isLoading && child?.pageUuid && !child?.wpPublishedAtUrl) ? 'rgba(127, 78, 173, 0.15)' : 'rgba(127, 78, 173, 0)',
                        width: 'max-content',
                        padding: !child?.wpPublishedAtUrl ? '8px 0px 6px 0px' : '8px 0px',
                        }}>
                        {!isLoading && child?.pageUuid && child?.wpPublishedAtUrl ? (
                          <OneClickPublisher getPopupContainer={() => wpContainerRef?.current || document.body} errorId={errorId} setErrorId={setErrorId} topicalMapName={child?.wpPublishedAtUrl} titleUuid={child?.titleUuid} uuid={child?.pageUuid}/>
                        ) : (
                          <>
                            {
                              !isLoading && child?.pageUuid ?
                                !wpArray?.length ? (
                                  <PluginButton onClick={() => setShowInstructionsModal(true)}><FontAwesomeIcon icon={faArrowUpFromLine} color={'#7F4EAD'} fontSize={14} />{title}</PluginButton>
                                ) : (
                                  <OneClickPublisher getPopupContainer={() => wpContainerRef?.current || document.body} errorId={errorId} setErrorId={setErrorId} topicalMapName={child?.wpPublishedAtUrl} titleUuid={child?.titleUuid} uuid={child?.pageUuid}/>
                                ) : (
                                  <PluginButton style={{cursor: 'not-allowed'}}><FontAwesomeIcon icon={faArrowUpFromLine} color={'#A3A4A4'} fontSize={14} />{title}</PluginButton>
                                )
                            }
                          </>
                        )}
                      </ViewButton></FreezeWrapper>) : (<FreezeWrapper><ViewButton
                        onClick={() => {
                          if (!isLoading && child?.pageUuid && child?.wpPublishedAtUrl) {
                            openUrl(child?.wpPublishedAtUrl, '_blank');
                          } else {
                            return;
                          }
                        }
                        }
                        style={{color: !isLoading && child?.pageUuid && child?.wpPublishedAtUrl ? '#7F4EAD' : '#A3A4A4',
                          backgroundColor: !isLoading && child?.pageUuid && child?.wpPublishedAtUrl ? 'rgba(127, 78, 173, 0.15)' : 'rgba(127, 78, 173, 0)',
                          width: '162px',
                          padding: '8px',
                          cursor: (!isLoading && child?.pageUuid && child?.wpPublishedAtUrl) ? 'pointer' : 'not-allowed',
                        }}>
                        {!isLoading && child?.pageUuid && child?.wpPublishedAtUrl ? (<>
                          <FontAwesomeIcon icon={faCircleCheck} color='#7F4EAD' fontSize={14} />{title}
                        </>) : (<>
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#A3A4A4' fontSize={14} />{title}
                        </>)}
                      </ViewButton></FreezeWrapper>)}
                  </div>
                  {index !== topicalMapStepsTitles.length -1 && <FontAwesomeIcon icon={faChevronRight} color='#A3A4A4' fontSize={14} />}
                </div>
              ))}
            </div>
          </TopicalMapStepsWrapper>
        </ActionsWrapper>
        <DeleteRapper style={{display: 'flex', alignItems: 'center', flexBasis: '10%', justifyContent: 'center'}} isGenerating={isGenerating}>
          {child?.titleUuid === deleteId ? (
            <StyledSpin color='#7F4EAD' />
          ) : (
            <FreezeWrapper removeTooltip={!isLoading && child?.pageUuid && child?.wpPublishedAtUrl}>
              <Tooltip title={isGenerating ? `Can't delete while generating` : ''}>
                <FontAwesomeIcon
                  icon={faTrash}
                  className='trash'
                  fontSize={16}
                  onClick={() => {
                    if (isGenerating) return;
                    setOpenModal(true);
                    setDeleteModalData({id: child?.id, name: child?.name, titleUuid: child?.titleUuid});
                  }}
                />
              </Tooltip>
            </FreezeWrapper>
          )}
        </DeleteRapper>
      </div>
    );
  };

  return (
    <>
      <div style={{height: '100%'}} ref={wpContainerRef}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            {!isInContentTopicMap && (<FiltersRadioGroup value={tableData} onChange={e => setTableData(e.target.value)}>
              {filterRadioButtons.map((option, index) => (
                <Radio.Button value={option.value} key={index}>
                  <div>{option.label}({tableDataMapping[option.value]})</div>
                </Radio.Button>
              ))}
            </FiltersRadioGroup>)}
            {!isInContentTopicMap && <FiltersDropdownWrapper>
              <Dropdown
                overlay={categoryMenu}
                trigger={['click']}
                getPopupContainer={node => node}
                placement='bottomLeft'
                visible={openCategoryDropdown}
                onVisibleChange={value => onChangeCategoryVisibility(value)}
              >
                <div style={{cursor: 'pointer'}}>Category <FontAwesomeIcon icon={faSortDown} color='#4E5156' fontSize={14}/></div>
              </Dropdown>
              {searchIntentDropdown}
              {searchInput}
            </FiltersDropdownWrapper>}
          </div>
          {!isInContentTopicMap && <ExportButton modalStyle={'ottoModalTable'}/>}
        </div>
        <div style={{height: '94%'}}>
          <div style={{marginTop: '10px', height: '100%'}}>
            <div style={{marginBottom: '6px', display: 'flex', width: '100%'}}>
              <CategoryTitle style={{borderRadius: '8px 0px 0px 8px', flexBasis: '16%'}}>CATEGORY</CategoryTitle>
              <CategoryTitle style={{flexBasis: '6%'}}>ADD TO<br />TOPIC MAP</CategoryTitle>
              <CategoryTitle style={{flexBasis: '5%'}}>SI</CategoryTitle>
              <CategoryTitle style={{flexBasis: '16%'}}>KEYWORD</CategoryTitle>
              <CategoryTitle style={{flexBasis: '25%'}}>TITLE</CategoryTitle>
              <CategoryTitle style={{borderRadius: '0px 8px 8px 0px', flexBasis: '30%'}}>ACTIONS</CategoryTitle>
            </div>
            <BodyWrapper style={{height: '88%'}}>
              {topicMapDataList.map((data, index) => {
                let collapsedSection;
                let childrenLength = 0;
                if (Array.isArray(topicalMap?.formattedData) && topicalMap?.formattedData?.length) {
                  collapsedSection = topicalMap?.formattedData?.find(obj => obj?.name === data?.name);
                  const keywords = collapsedSection?.keywords ?? [];
                  const flattenedTitles = keywords.flatMap(keyword => keyword?.titles ?? []);
                  childrenLength = flattenedTitles.length;
                }

                const isExpanded = keysWithChildren.includes(data.key);

                return (
                  <StyledSingleData key={index}>
                    <div style={{flexBasis: '16%'}}>
                      <SectionTitle onClick={() => onChangeActive(!isExpanded, data?.name)}>
                        <FontAwesomeIcon
                          style={{cursor: 'pointer', marginTop: '4px'}}
                          icon={isExpanded ? faChevronUp : faChevronDown}
                          color='#000000'
                          fontSize={14}
                        />
                        {data?.name}
                      </SectionTitle>
                    </div>
                    <div style={{flexBasis: '84%', display: !isExpanded ? 'flex' : 'block', alignItems: !isExpanded ? 'center' : 'flex-start'}}>
                      {!isExpanded ? (
                        <ExpandableCount onClick={() => onChangeActive(!isExpanded, data?.name)}>Expand {childrenLength} article ideas</ExpandableCount>
                      ) : (
                        data?.children.map(child =>
                          child?.children?.length ? (
                            child?.children.map((grandChild, grandChildIndex) => <IdeaRow key={grandChildIndex} child={grandChild} />)
                          ) : (
                            <IdeaRow key={child?.name || Math.random()} child={child} />
                          ),
                        )
                      )}
                    </div>
                  </StyledSingleData>
                );
              })}
            </BodyWrapper>
          </div>
        </div>
      </div>
      {openModal && <Wrapper>
        <Modal
          onClose={() => {
            setOpenModal(false);
            setDeleteModalData(null);
          }}
          visible={openModal}
          className={'customConfirmationModal'}
        >
          <ModalWrapper>
            <p>Are you sure you want to delete this article? </p>
            <p className={'warningText'}>This action cannot be undone.</p>
            <DeleteButton
              buttonType='transparent' size='lg'
              loading={deletingTopicalMap}
              onClick={async () => deleteArticle(deleteModalData?.name, [...topicalMapArticle, {
                id: deleteModalData?.id,
                titleUuid: deleteModalData?.titleUuid,
                title: deleteModalData?.name,
                isLoading: true,
                pageUuid: '',
              }])}>
            Delete
            </DeleteButton>
            <BackButton onClick={() => {
              setOpenModal(false);
              setDeleteModalData(null);
            }}
            buttonType='transparent'
            size='lg'>
            Go Back
            </BackButton>
          </ModalWrapper>
        </Modal>
      </Wrapper>}
    </>
  );
});

const FilterButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .ant-dropdown-trigger {
    border: 1px solid #CDCDCD;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #121212;
    padding: 5px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    svg {
      margin-top: -8px;
    }
  }
`;

const FiltersDropdownWrapper = styled(FilterButtons)`
  .ant-dropdown-trigger {
    padding: 3px 10px;
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    height: 50px;
    border: 1px solid #4E51561F;
    background-color: #4E515612;
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: #7F4EAD33;
      border: 1px solid #7F4EAD1F;
    }
    &:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: transparent;
    }
    span:nth-child(2) {
      height: 46px;
      display: flex;
      align-items: center;
      gap: 10px;
      div {
        display: flex;
        flex-direction: column;
        line-height: normal;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #121212;
        span {
          line-height: normal;
          font-family: 'Inter', sans-serif;
          font-size: 12px;
          font-weight: 400;
          color: #4E5156;
        }
      }
    }
    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }
    &:last-child {
      border-radius: 0px 8px 8px 0px;
    }
  }
`;

const FiltersRadioGroup = styled(StyledRadioGroup)`
  .ant-radio-button-wrapper {
    height: 30px;
    border: 1px solid #CDCDCD;
    background-color: #FFFFFF;
    span:nth-child(2) {
      height: 28px;
    }
  }
`;

const StyledTag = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

const Keyword = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #121212;
  padding: 0px 12px;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 12px;
  color: #121212;
  display: flex;
  align-items: center;
`;

const ViewButton = styled.div`
  width: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  padding: 8px 12px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #7F4EAD;
  background-color: rgba(127, 78, 173, 0.15);
  line-height: normal;
`;

const TableCheckbox = styled(Checkbox)`
  .ant-checkbox {
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #7F4EAD !important;
    }
    .ant-checkbox-inner {
      border-radius: 4px !important;
      &::after {
        top: 42%;
      }
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #7F4EAD;
        border-color: #7F4EAD;
      }
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: #7F4EAD !important;
    }
    }
  }
`;

const ExpandableCount = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #2D6CCA;
  line-height: normal;
  cursor: pointer;
`;

const SectionTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #121212;
  padding: 12px 12px;
  display: flex;
  gap: 8px;
  cursor: pointer;
`;

const CategoryTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #121212;
  background-color: #F2F2F5;
  padding: 12px 12px;
  display: flex;
  align-items: center;
`;

const CheckboxWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #121212;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionsWrapper = styled.div``;

const BodyWrapper = styled.div`
  overflow: hidden;
  overflow-y: auto;
  height: 410px;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;

const StyledSingleData = styled.div`
  width: 100%;
  display: flex;
  box-shadow: 0px 1px 0px 0px #E7E7E7;
`;

const DeleteRapper = styled.div<{isGenerating: boolean}>`
  .trash {
    color: ${p => p.isGenerating ? '#A3A4A4' : '#7F4EAD'};
    :hover {
      color: ${p => p.isGenerating ? '#A3A4A4' : 'red'};
      cursor: ${p => p.isGenerating ? 'not-allowed' : 'pointer'};
    }
  }
`;
