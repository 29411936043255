import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRotateLeft, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {Col, Empty, Form, Row, Checkbox} from 'antd';
import {useStore} from '@/store/root-store';
import * as echarts from 'echarts';
import {LoadingChart} from '@/components/dashboard/pages/site-explorer-page/detail-page/components/loadingChart';
import {Informational, Transitional, Commercial, Navigational} from '../../../Constants';
import {UrlFilterInput} from '../../../style';
import {getLeafNodes, spaceBetweenNodes} from '../../../Utils/helper-func';

interface TopicalMapModalGraphContentProps {
  isNodeSelected: any;
  func: any;
  isCategoryDropdown: any;
  setIsCategoryDropdown: any;
  searchValue: any;
  dependency: any;
  ExportButton: any;
  topicalMapDataList: any;
  getTreeData: any;
  dataLoader: any;
  topicalMapDataState: any;
  zoom: any;
  setZoom: any;
  handleResetZoom: any;
  handleOnChangeSearch: any;
  searchIntentDropdown: any;
  activeCategories: any[];
  setActiveCategories: Dispatch<SetStateAction<any[]>>;
  categoriesOptions: any[];
  isInContentTopicMap?: boolean;
}

let myChart;

export const TopicalMapModalGraphContent: React.FC<TopicalMapModalGraphContentProps> = (
  {
    isNodeSelected,
    func,
    isCategoryDropdown,
    setIsCategoryDropdown,
    searchValue,
    dependency,
    ExportButton,
    topicalMapDataList,
    getTreeData,
    dataLoader,
    topicalMapDataState,
    zoom,
    setZoom,
    handleResetZoom,
    handleOnChangeSearch,
    searchIntentDropdown,
    activeCategories,
    setActiveCategories,
    categoriesOptions,
    isInContentTopicMap,
  }) => {
  const {settings: {customer: {profile: {whitelabelOtto}}}} = useStore('');

  const [clickedValue, setClickedValue] = useState<any>(0);
  const [tableData, setTableData] = useState('all-ideas');

  useEffect(() => {
    const id = document.getElementById('linkgraph-chart');
    if (id && (dependency)) {
      myChart = echarts.init(id);
      const option = {
        tooltip: {
          formatter: function(...props: any) {
            const {name} = props[0].data;
            return name;
          },
          trigger: 'item',
          triggerOn: 'mousemove',
        },
        series: [
          {
            type: getLeafNodes(topicalMapDataState).length < 1 ? 'hidden' : 'tree',
            zoom: zoom,
            data: [topicalMapDataState],
            roam: 'move',
            top: '1%',
            left: '1%',
            bottom: getLeafNodes(topicalMapDataState)?.length > 3 ? spaceBetweenNodes(topicalMapDataState) : '1%',
            right: '45%',
            symbolSize: 10,
            status: 'unselect',
            label: {
              position: 'top',
              verticalAlign: 'middle',
              color: '#333333',
              fontSize: 12,
              formatter: function(d) {
                let icon = '';
                let space = '';
                if (d.data?.searchIntent?.length > 0) {
                  space = '     ';
                  space = space.repeat(d.data?.searchIntent.length);
                  icon = d.data?.searchIntent?.map(intent => `{${intent}|} `)?.join('');
                }
                if (d.data.titleName) {
                  return `${icon}{title|${d.data.titleName}}\n${space}{keyword|${d.data.name}}`;
                }
                return `{keyword|${d.data.name}}`;
              },
              rich: {
                title: {
                  color: '#333333',
                  fontWeight: 400,
                  fontSize: '12px',
                },
                keyword: {
                  color: '#4E5156',
                  fontWeight: 400,
                  fontSize: '10px',
                },
                transactional: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Transitional),
                  },
                },
                informational: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Informational),
                  },
                },
                commercial: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Commercial),
                  },
                },
                navigational: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Navigational),
                  },
                },
              },
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
              },
            },
            emphasis: {
              focus: 'descendant',
            },
            selectedMode: true,
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
        notMerge: true,
        lazyUpdate: true,
      };
      myChart.setOption(option);
    }
  }, [zoom]);

  useEffect(() => {
    const id = document.getElementById('linkgraph-chart');
    if (id && topicalMapDataState?.children?.length) {
      myChart = echarts.init(id);
      const option = {
        tooltip: {
          formatter: function(...props: any) {
            const {name, isSelected} = props[0].data;
            return `${name}<hr/>Click to ${isSelected ? 'remove' : 'add'} this article idea to your topical map.<br/>You can create articles in the Table view or on the main ${whitelabelOtto} page.`;
          },
          trigger: 'item',
          triggerOn: 'mousemove',
        },
        series: [
          {
            type: getLeafNodes(topicalMapDataState).length < 1 ? 'hidden' : 'tree',
            data: [topicalMapDataState],
            roam: 'move',
            top: '1%',
            left: '1%',
            bottom: getLeafNodes(topicalMapDataState)?.length > 3 ? spaceBetweenNodes(topicalMapDataState) : '1%',
            right: '45%',
            symbolSize: 10,
            status: 'unselect',
            label: {
              position: 'top',
              verticalAlign: 'middle',
              color: '#333333',
              fontSize: 12,
              formatter: function(d) {
                let icon = '';
                let space = '';
                if (d.data?.searchIntent?.length > 0) {
                  space = '     ';
                  space = space.repeat(d.data?.searchIntent.length);
                  icon = d.data?.searchIntent?.map(intent => `{${intent}|} `)?.join('');
                }
                if (d.data.titleName) {
                  return `${icon}{title|${d.data.titleName}}\n${space}{keyword|${d.data.name}}`;
                }
                return `{keyword|${d.data.name}}`;
              },
              rich: {
                title: {
                  color: '#333333',
                  fontWeight: 400,
                  fontSize: '12px',
                },
                keyword: {
                  color: '#4E5156',
                  fontWeight: 400,
                  fontSize: '10px',
                },
                transactional: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Transitional),
                  },
                },
                informational: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Informational),
                  },
                },
                commercial: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Commercial),
                  },
                },
                navigational: {
                  align: 'center',
                  backgroundColor: {
                    image: encodeSvgToDataURI(Navigational),
                  },
                },
              },
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
              },
            },
            emphasis: {
              focus: 'descendant',
            },
            selectedMode: true,
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
        notMerge: true,
        lazyUpdate: true,
      };
      myChart.setOption(option);
    }
  }, [topicalMapDataState]);

  useEffect(() => {
    if (clickedValue && myChart) {
      const updatedData = isNodeSelected(clickedValue, {...topicalMapDataState});
      func(updatedData);
      const option = {
        series: [
          {
            type: getLeafNodes(topicalMapDataState).length < 1 ? 'hidden' : 'tree',
            data: [topicalMapDataState],
            roam: 'move',
            top: '1%',
            left: '1%',
            bottom: getLeafNodes(topicalMapDataState)?.length > 3 ? spaceBetweenNodes(topicalMapDataState) : '1%',
            right: '45%',
            symbolSize: 10,
            status: 'unselect',
            label: {
              position: 'top',
              verticalAlign: 'middle',
              fontSize: 9,
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
              },
            },
            emphasis: {
              focus: 'descendant',
            },
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      };
      myChart.setOption(option);
      setClickedValue(0);
    } else if (clickedValue) {
      const updatedData = isNodeSelected(clickedValue, {...topicalMapDataState});
      func(updatedData);
    }
  }, [clickedValue]);

  useEffect(() => {
    if (topicalMapDataState?.children?.length && myChart) {
      myChart?.on('click', params => {
        setClickedValue(params?.value);
      });
    }
  }, [topicalMapDataState]);

  useEffect(() => {
    if (dataLoader && topicalMapDataState && topicalMapDataState?.children?.length) {
      getTreeData();
    } else {
      if (topicalMapDataList?.children && !isCategoryDropdown) {
        if (tableData === 'topic-map') {
          const data = getFilteredCategory(topicalMapDataList?.children, 'isSelected');
          setActiveCategories(data);
        } else if (tableData === 'created') {
          const data = getFilteredCategory(topicalMapDataList?.children, 'pageUuid');
          setActiveCategories(data);
        } else {
          if (activeCategories?.length !== categoriesOptions?.length) {
            setActiveCategories(categoriesOptions?.map(item => item?.value));
          }
        }
      } else {
        setIsCategoryDropdown(false);
      }
      if (myChart) {
        myChart.dispatchAction({
          type: 'restore',
        });
      }
      setZoom(1);
    }
  }, [tableData]);

  const encodeSvgToDataURI = svg => {
    return (
      'data:image/svg+xml;charset=utf8,' +
      encodeURIComponent(
        svg.replace(
          '<svg',
          svg.indexOf('xmlns') > -1 ? '<svg' : '<svg xmlns="http://www.w3.org/2000/svg"',
        ),
      )
    );
  };

  const onChange = (event: any, value: any) => {
    setIsCategoryDropdown(true);
    setTableData('all-ideas');
    setActiveCategories(prev =>event.target.checked ? [...prev, value] : prev?.filter(item => item !== value));
  };

  const getFilteredCategory = (data, key) => {
    return data?.filter(parent => parent.children.some(child => child[key]))?.map(parent => parent.name);
  };


  return (
    <>
      <Row gutter={16}>
        <Col span={5}>
          <Row justify='space-between' style={{maxWidth: 250, marginTop: 10}}>
            <Col>
              <CategoryHeading>Categories</CategoryHeading>
            </Col>
            <Col>
              <Row gutter={14}>
                <Col>
                  <SelectAll onClick={() => {
                    setIsCategoryDropdown(true);
                    setTableData('all-ideas');
                    setActiveCategories(categoriesOptions?.map(item => item?.value));
                  }}>
                    Select all
                  </SelectAll>
                </Col>
                <Col>
                  <SelectAll onClick={() => {
                    setIsCategoryDropdown(true);
                    setTableData('all-ideas');
                    setActiveCategories([]);
                  }}>
                    Deselect all
                  </SelectAll>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={19}>
          <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: isInContentTopicMap ? 'flex-end' : 'space-between'}}>
            {!isInContentTopicMap && (<div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
              <UrlFilterInput style={{border: '1px solid #CDCDCD'}} value={searchValue} onChange={e => handleOnChangeSearch(e.target.value)} placeholder='Search by title & keyword'/>
              <FilterButtons style={{minWidth: '188px'}}>
                {searchIntentDropdown}
              </FilterButtons>
            </div>)}
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
              <ZoomNote>
                <FontAwesomeIcon icon={faCircleInfo} fontSize={16} color='#A3A4A4'/>
                <div className='text-note'>Click, hold and drag around to explore the diagram.<br/>Select ideas to add them to the topic map.</div>
              </ZoomNote>
              <ZoomButtons>Zoom:
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} fontSize={16} color='#4E5156' style={{cursor: 'pointer'}} onClick={() => {
                  setZoom(zoom + 0.1);
                }}/>
                <FontAwesomeIcon icon={faMagnifyingGlassMinus} fontSize={16} color='#4E5156' style={{cursor: 'pointer'}} onClick={() => {
                  if (zoom > 1) {
                    setZoom(zoom - 0.1);
                  }
                }}/>
                <FontAwesomeIcon icon={faArrowRotateLeft} fontSize={16} color='#4E5156' style={{cursor: 'pointer'}} onClick={handleResetZoom}/>
              </ZoomButtons>
              {!isInContentTopicMap && <ExportButton modalStyle={'ottoModalChart'}/>}
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{height: '96%'}}>
        <Col span={5}>
          <CategoryFormItem className='category-column' name='categories'>
            <StyledCheckboxWrapper>
              {categoriesOptions.map(categories => <div key={categories?.value} className={activeCategories.includes(categories.value) ? 'checked-checkbox-wrapper' : 'checkbox-wrapper'}>
                <Checkbox onChange={e => onChange(e, categories.value)} checked={activeCategories.includes(categories.value)}>
                  <span className='label'>{categories?.label}</span>
                </Checkbox>
              </div>)}
            </StyledCheckboxWrapper>
          </CategoryFormItem>
        </Col>
        <Col span={18}>
          {!topicalMapDataState?.children?.length && <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><StyledEmpty /></div>}
          {dataLoader ? <LoadingChart /> :
            (!dataLoader && (typeof topicalMapDataState === 'object' && 'children' in topicalMapDataState)) ? (
              <div style={{width: '950px', height: '100%'}} id='linkgraph-chart'></div>
            ) : <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><StyledEmpty /></div>}
        </Col>
      </Row>
    </>
  );
};

const StyledCheckboxWrapper = styled.div<{checked?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 4px;
  height: 446px;
  overflow: hidden;
  overflow-y: auto;

  .checkbox-wrapper, .checked-checkbox-wrapper {
    width: 245px !important;
    font-size: 12px;
    padding: 8px 10px !important;
    height: auto !important;
    border-radius: 8px;
    background-color: #F9F9FB;
    border: 1px solid #E8E8E8;
    line-height: normal;
    .ant-checkbox:not(.ant-checkbox-checked) {
      display: none;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: transparent !important;
        border-color: transparent !important;
        border-radius: 0px !important;
        overflow: hidden;
      }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #121212;
    }
    ${p => p?.checked ? `
      border-color: #2E343F !important;
      background-color: #2E343F !important;
      border: 1px solid #2E343F;
    ` : ''}
    .ant-checkbox-inner {
      border-radius: 5px;
    }
  }
  .checked-checkbox-wrapper {
    background-color: #7F4EAD33;
    .label {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 12px;
      color: #121212;
    }
  }
  .ant-checkbox-checked::after {
    border-color: #fff;
    border-radius: 5px;
    display: none;
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
    border-radius: 6px;
  }
  
`;

const CategoryFormItem = styled(Form.Item)`
  padding-bottom: 0px; 
  margin-bottom: 0px;
  height: 100%; 
  overflow: auto;
  width: fit-content;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;

const CategoryHeading = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #4E5156;
`;

const SelectAll = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  color: #2D6CCA;
  cursor: pointer;
`;

const StyledEmpty = styled(Empty)`
  position: absolute;
`;

const ZoomButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  border: 1px solid #CDCDCD;
  padding: 5px 10px;
  border-radius: 8px;
`;

const FilterButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .ant-dropdown-trigger {
    border: 1px solid #CDCDCD;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #121212;
    padding: 5px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    svg {
      margin-top: -8px;
    }
  }
`;

const ZoomNote = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .text-note {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #121212;
    line-height: normal;
  }
`;

